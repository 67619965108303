<template>
    <div class="tab-panel" v-show="activeTab == 'details'">
        <div
            class="informations-bloc blueType no-mobile"
            @click="checkRight('G_RESIDENCES') && editAction()"
        >
            <Infos :name="name" :adress="adress" :comment="comment" />
        </div>
        <ResidenceBreadcrumb :path="path" />
        <!-- ACTIONS -->
        <div
            class="actions"
            v-if="checkRight('G_RESIDENCES') || checkRight('G_APP')"
        >
            <!-- create -->
            <basic-button
                class="action create"
                color="orange"
                @click="createAction()"
            >
                <i class="fas fa-plus"></i>
                <span class="mobile">
                    {{ $t("entityDetails.actions.create") }}
                </span>
            </basic-button>
            <!-- import -->
            <basic-button
                color="blue"
                class="action import dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <i class="far fa-arrow-alt-circle-up"></i>
                <span class="label mobile">
                    {{ $t("entityDetails.actions.import") }}
                </span>
            </basic-button>
            <ul class="dropdown-menu dropdown-import" aria-labelledby="import">
                <a
                    @click="
                        goToV1View('data/import_param/type=1&cageid=' + this.id)
                    "
                    class="dropdown-item"
                >
                    <i class="fas fa-upload"></i>
                    {{ $t("entityDetails.actions.assistedImport") }}
                </a>
                <a
                    @click="
                        goToV1View('data/import_param/type=0&cageid=' + this.id)
                    "
                    class="dropdown-item"
                >
                    <i class="fas fa-upload"></i>
                    {{ $t("entityDetails.actions.manualImport") }}
                </a>
            </ul>
            <!-- export -->
            <basic-button
                color="blue"
                class="action export dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <i class="far fa-arrow-alt-circle-down"></i>
                <span class="label mobile">
                    {{ $t("entityDetails.actions.export") }}
                </span>
            </basic-button>
            <ul class="dropdown-menu dropdown-export" aria-labelledby="export">
                <a
                    :href="
                        'data/imprim.php?e=1&SID=' +
                        this.$store.getters['auth/getIntratoneDev2SID']
                    "
                    class="dropdown-item"
                >
                    <i class="fas fa-file-excel"></i>
                    {{ $t("entityDetails.actions.stairwellExportA") }}
                </a>
                <a
                    :href="
                        'data/imprim.php?e=2&SID=' +
                        this.$store.getters['auth/getIntratoneDev2SID']
                    "
                    class="dropdown-item"
                >
                    <i class="fas fa-file-excel"></i>
                    {{ $t("entityDetails.actions.stairwellExportB") }}
                </a>
                <a
                    @click="goToV1View('print/batiment/index/id=' + id, true)"
                    class="dropdown-item"
                >
                    <i class="fas fa-list"></i>
                    {{ $t("entityDetails.actions.stairwellExportC") }}
                </a>
                <a
                    v-if="checkPrivilege('LAST_KEY')"
                    class="dropdown-item"
                    @click="$router.push({ name: 'ExportFobsStairwell' })"
                >
                    <i class="fas fa-list"></i>
                    {{ $t("exportFobs.fobsTitle") }}
                </a>
            </ul>
        </div>

        <!-- ACCESSES -->
        <div class="accesses-materials">
            <div class="middle-block">
                <h1 class="panel-title">
                    {{ $t("entityDetails.internalAccesses") }}
                </h1>
                <DisplayModeAccessCard />
            </div>
            <div
                :class="'accesses' + '-' + displayMode"
                v-if="accesses.length > 0"
            >
                <div
                    class="accesses-list-grid-title"
                    v-if="displayMode == 'list'"
                >
                    <div></div>
                    <div>
                        {{ $t("residencesList.arrayColumns.name") }}
                    </div>
                    <div>
                        {{ $t("centralUnitsList.arrayColumns.option") }}
                    </div>
                </div>
                <access-card
                    v-for="item in accesses"
                    :key="item.id"
                    :access="item"
                    :entityParent="entity"
                    :idParent="id"
                    :display="displayMode"
                >
                </access-card>
            </div>
            <div class="accesses" v-else>
                <access-card
                    :isCreator="true"
                    :entityParent="entity"
                    :display="displayMode"
                >
                </access-card>
            </div>
            <div
                class="accesses-show-button"
                v-if="accesses.length < countAccess"
            >
                <button @click="showMore">
                    <span>
                        {{ $t("button.seeAll") }}
                        <i class="fas fa-chevron-circle-down"></i>
                    </span>
                </button>
            </div>
        </div>

        <!-- APARTMENTS -->
        <div class="apartments">
            <apartments-list :apartments="apartments"></apartments-list>
        </div>
    </div>

    <!-- INTERCOMS -->
    <div class="tab-panel" v-show="activeTab == 'intercoms'">
        <intercoms-list :intercoms="intercoms"></intercoms-list>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"
import BasicButton from "@/components/basic/BasicButton.vue"
import ApartmentsList from "@/components/entities/ApartmentsList.vue"
import IntercomsList from "@/components/entities/IntercomsList.vue"
import AccessCard from "@/components/entities/AccessCard.vue"
import Infos from "@/views/stairwell/Infos"
import DisplayModeAccessCard from "../../components/DisplayModeAccessCard.vue"
import ResidenceBreadcrumb from "@/components/entities/ResidenceBreadcrumb.vue"
import { mapState } from "vuex"

import { entityType } from "@/enums"

export default {
    name: "StaiwellDetails",
    components: {
        BasicButton,
        AccessCard,
        ApartmentsList,
        IntercomsList,
        DisplayModeAccessCard,
        ResidenceBreadcrumb,
    },
    mixins: [v1mixin],
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        apartments: {
            type: Object,
            default: () => ({}),
        },
        activeTab: {
            type: String,
            default: "",
        },
        entityParent: {
            type: String,
            default: "",
        },
        path: {
            type: Object,
            default: () => ({}),
        },
        idParent: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        comment: {
            type: String,
            default: "",
        },
        countAccess: {
            type: String,
            default: "0",
        },
        adress: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        accesses: {
            type: Array,
        },
        intercoms: {
            type: Array,
        },
        maxAppartments: {
            type: Number,
        },
    },
    data() {
        return {
            // Entity type and data
            entity: entityType.STAIRWELL,
            // Show all accesses
            showAllAccesses: false,
            // Types of entity
            entityType,
            accessPage: 1,
            accessTotalPage: 1,

            baseUrlImprim: process.env.VUE_APP_PHP_SITE_URL,
        }
    },
    computed: {
        allStairwells() {
            return this.buildings.concat(this.stairwells)
        },
        ...mapState({
            displayMode: (state) => state.account.displayAccessCard,
        }),
    },
    methods: {
        createAction() {
            this.openV1Modal("data/cage/action/cage_add", "Act_Zone")
        },
        editAction() {
            this.goToV1View("data/cage/cage/edit=1&type=CAGE&id=" + this.id)
        },
        showMore() {
            this.$emit("showMore")
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

.middle-block {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    h1 {
        margin: 0;
    }

    div {
        display: flex;

        gap: 1em;
        height: max-content;

        span {
            color: $grey-darker;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            padding: 0 1em;
            font-family: $font_avenir_heavy;
        }

        i {
            color: $grey-darker;
            cursor: pointer;
            padding: 8px;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $grey-neutral;
                color: $orange-light;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

.accesses-card {
    display: grid !important;
    @media all and (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media all and (min-width: 1280px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media all and (max-width: 720px) {
        grid-template-columns: repeat(1, 1fr);
    }
    width: 100%;
    gap: 2em;
    margin: 0;
}

.accesses-list-grid-title {
    width: 100%;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 10fr 1fr;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    padding: 1em 0;

    div {
        font-family: $font_avenir_heavy;
        color: $grey-darker;
    }

    div:nth-child(1) {
        max-width: 150px;
        min-width: 100px;
    }

    div:nth-child(3) {
        justify-content: end;
        display: flex;
    }
}
.accesses-list {
    display: flex;
    flex-direction: column;

    div {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;

        div {
            display: grid;
            grid-template-columns: 1fr, 1fr 1fr;
        }
    }
}
</style>
